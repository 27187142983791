document.addEventListener('DOMContentLoaded', () => {
    'use strict'

    /*==================================
     START THE PAGE LOADER
    ==================================*/
    window.addEventListener("load", function () {
        const loader = document.querySelector(".loader-page");
        loader.className += " hidden";
    });

    // DROPDOWN SELECT 

    var Select = document.querySelectorAll(".dropdown-select");
    Select.forEach(function (select) {
        NiceSelect.bind(select);
    });

    /*==================================
     TOOLTIP
    ==================================*/
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))


    // FILEPOND - UPLOAD FILES
    FilePond.registerPlugin(
        FilePondPluginFileValidateType,
        FilePondPluginFileValidateSize,
        FilePondPluginImagePreview,
        FilePondPluginImageResize,
        FilePondPluginImageTransform
    );

    FilePond.setOptions({
        labelIdle: `اسحب صورتك وأفلتها أو <span class="filepond--label-action">تصفح</span>`,
        labelInvalidField: 'الحقل يحتوي على ملفات غير صالحة',
        labelFileWaitingForSize: 'بانتظار الحجم',
        labelFileSizeNotAvailable: 'الحجم غير متاح',
        labelFileLoading: 'بالإنتظار',
        labelFileLoadError: 'حدث خطأ أثناء التحميل',
        labelFileProcessing: 'يتم الرفع',
        labelFileProcessingComplete: 'تم الرفع',
        labelFileProcessingAborted: 'تم إلغاء الرفع',
        labelFileProcessingError: 'حدث خطأ أثناء الرفع',
        labelFileProcessingRevertError: 'حدث خطأ أثناء التراجع',
        labelFileRemoveError: 'حدث خطأ أثناء الحذف',
        labelTapToCancel: 'انقر للإلغاء',
        labelTapToRetry: 'انقر لإعادة المحاولة',
        labelTapToUndo: 'انقر للتراجع',
        labelButtonRemoveItem: 'مسح',
        labelButtonAbortItemLoad: 'إلغاء',
        labelButtonRetryItemLoad: 'إعادة',
        labelButtonAbortItemProcessing: 'إلغاء',
        labelButtonUndoItemProcessing: 'تراجع',
        labelButtonRetryItemProcessing: 'إعادة',
        labelButtonProcessItem: 'رفع',
        labelMaxFileSizeExceeded: 'الملف كبير جدا',
        labelMaxFileSize: 'حجم الملف الأقصى: {filesize}',
        labelMaxTotalFileSizeExceeded: 'تم تجاوز الحد الأقصى للحجم الإجمالي',
        labelMaxTotalFileSize: 'الحد الأقصى لحجم الملف: {filesize}',
        labelFileTypeNotAllowed: 'ملف من نوع غير صالح',
        fileValidateTypeLabelExpectedTypes: 'تتوقع {allButLastType} من {lastType}',
        imageValidateSizeLabelFormatError: 'نوع الصورة غير مدعوم',
        imageValidateSizeLabelImageSizeTooSmall: 'الصورة صغير جدا',
        imageValidateSizeLabelImageSizeTooBig: 'الصورة كبيرة جدا',
        imageValidateSizeLabelExpectedMinSize: 'الحد الأدنى للأبعاد هو: {minWidth} × {minHeight}',
        imageValidateSizeLabelExpectedMaxSize: 'الحد الأقصى للأبعاد هو: {maxWidth} × {maxHeight}',
        imageValidateSizeLabelImageResolutionTooLow: 'الدقة ضعيفة جدا',
        imageValidateSizeLabelImageResolutionTooHigh: 'الدقة مرتفعة جدا',
        imageValidateSizeLabelExpectedMinResolution: 'أقل دقة: {minResolution}',
        imageValidateSizeLabelExpectedMaxResolution: 'أقصى دقة: {maxResolution}'
    });

    const FILEPOND_ITEMS = document.querySelectorAll('input[type="file"]');
    Array.from(FILEPOND_ITEMS).forEach(FILEPOND_ITEMS => {
        FilePond.create(FILEPOND_ITEMS);
    });


    var sticky = new Sticky('.sticky-item');

    /*==================================
       MAKES THE CURRENT LINK CONTAINING A OF CLASS "ACTIVE"
      ==================================*/
    const activePage = window.location.pathname;
    const navLinks = document.querySelectorAll('.nav-menu-main a').forEach(link => {
        if (link.href.includes(`${activePage}`)) {
            link.classList.add('active');
        }
    });

    /*==================================
     COPY
    ==================================*/
    new ClipboardJS('.btn');

    /*==================================
     OS PREFERS COLOR SCHEME
    ==================================*/
    function detectColorScheme() {
        var theme = "light";

        if (localStorage.getItem("theme")) {
            if (localStorage.getItem("theme") == "dark") {
                var theme = "dark";
            }
        } else if (!window.matchMedia) {
            return false;
        } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            var theme = "dark";
        }

        if (theme == "dark") {
            document.documentElement.setAttribute("data-theme", "dark");
        }

    }
    detectColorScheme();

    /*==================================
     DARK MODE ACTIVATION - SWITCH
    ==================================*/
    const toggleSwitch = document.querySelector('.theme-switch');
    function switchTheme(e) {
        if (e.target.checked) {
            localStorage.setItem('theme', 'dark');
            document.documentElement.setAttribute('data-theme', 'dark');
            toggleSwitch.checked = true;
        } else {
            localStorage.setItem('theme', 'light');
            document.documentElement.setAttribute('data-theme', 'light');
            toggleSwitch.checked = false;
        }
    }
    toggleSwitch.addEventListener('change', switchTheme, false);
    if (document.documentElement.getAttribute("data-theme") == "dark") {
        toggleSwitch.checked = true;
    }

});
